import React from 'react';
import { Select, Input, Button, Form, Row, Col } from 'antd';
import SidebarModal from '../../../../components/sidebarModal/SidebarModal';
import { stateUS } from '../../../../helpers/state';

const { Option } = Select;

export const CreateForm = ({ createProps, createModalFormRef, handleOnFinish, createFormFields, roles, createBtnLoader, setToggleCreate }) => {
  return (
    <SidebarModal {...createProps} className="sidebar__modal--right" title="Create Country code">
      <Form name="create" ref={createModalFormRef} wrapperCol={{ span: 24 }} initialValues={{ remember: false }} onFinish={(data) => handleOnFinish({ name: 'create', formData: data })} fields={createFormFields}>
        <Form.Item name="countryCode" label="Country code:" rules={[{ required: true, message: 'Please input Country code!' }]}>
          <Input placeholder="Country code:" />
        </Form.Item>
        <Form.Item name="countryName" label="Country name:" rules={[{ required: true, message: 'Please input Country name!' }]}>
          <Input placeholder="Country name:" />
        </Form.Item>
        <Form.Item name="countryTimeZone" label="Country Timezone:" rules={[{ required: true, message: 'Please input Country Timezone!' }]}>
          <Input placeholder="Country Timezone:" />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8 }} className="mt-4 mb-0">
          <Row gutter={16}>
            <Col span={12}>
              <Button type="button" htmlType="button" className="btn btn-primary-link btn-block" onClick={() => setToggleCreate(false)}>
                <span>Cancel</span>
              </Button>
            </Col>
            <Col span={12}>
              {!createBtnLoader ? (
                <Button type="submit" htmlType="submit" className="btn btn-primary btn-block">
                  <span>Add Country code</span>
                </Button>
              ) : (
                <Button type="button" className="btn btn-primary-outline btn-block">
                  <span>Adding...</span>
                </Button>
              )}
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </SidebarModal>
  );
};
