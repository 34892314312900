import React from 'react';
import { Popover } from 'antd';
import { PERMISSIONS } from '../../../utils/permissions';
import Message from '../../../components/message/Message';
import { contentMessage } from '../../../helpers/contentMessage';
import Pagination from '../../../components/pagination/Pagination';

const Table = (props) => {
  const { data, handleToggle } = props;
  const { pagination } = props;
  const { updateActions, statusActions, deleteActions, location, groceryPermissions, getGroceryPermissions } = props;

  return (
    <>
      <table className="table">
        <thead className="thead">
          <tr>
            <th>Country code</th>
            <th>Country name</th>
            <th>Timezone</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody className="tbody">
          {data && data.length > 0 ? (
            data?.map((item, idx) => {
              return (
                <tr key={item._id}>
                  <td>{item.code}</td>
                  <td>{item.countryName}</td>
                  <td>{item.timeZone}</td>
                  {/* <td>
                    {item.phone && item.phone.length > 0 && (
                      <a href={`tel:${item.phone}`} className="link">
                        {item.phone}
                      </a>
                    )}
                  </td> */}
                  {/* <td>{item && item.user_role ? item.user_role : null}</td> */}

                  <td className="y-top">
                    {Object.values(groceryPermissions).some((key) => [PERMISSIONS.updateEmployees, PERMISSIONS.deleteEmployees].includes(key.name)) && (
                      <div className="actions actions-row">
                        <div className="actions-group" style={{ borderBottom: 0 }}>
                          <button className="btn btn-actions" style={{ marginBottom: 0, width: 'auto', marginLeft: 'auto', marginRight: 'auto' }} type="button" data-cy="actions-edit-employee" onClick={() => handleToggle({ name: 'update', state: updateActions, data: item._id })}>
                            <span className="icon">
                              <img alt="Edit" src="/assets/icons/edit-user.svg" />
                            </span>
                            <span className="text">
                              <strong>EDIT</strong>
                            </span>
                          </button>
                        </div>
                      </div>
                    )}
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={20}>
                <Message className="content-message">
                  <h2 style={{ color: '#666' }}>{contentMessage.dataNotFound}</h2>
                </Message>
              </td>
            </tr>
          )}
        </tbody>
      </table>

      {data && data.length > 0 && <Pagination {...pagination} />}
    </>
  );
};

export default Table;
