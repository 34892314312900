import React, { useEffect, useState } from 'react';
import { Popover } from 'antd';
import Checkbox from '../../../components/customCheckbox/CustomCheckbox';
import { PERMISSIONS } from '../../../utils/permissions';
import Message from '../../../components/message/Message';
import { contentMessage } from '../../../helpers/contentMessage';
import Pagination from '../../../components/pagination/Pagination';
import moment from 'moment';

const Table = (props) => {
  // TABLE
  const { data, handleCheckedRows, handleToggle } = props;
  // PAGINATION
  const { pagination } = props;

  // CUSTOM FOR ACTIONS
  const { updateActions, location, groceryPermissions, getGroceryPermissions } = props;
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);

  // HANDLE CHECKED ALL
  const handleSelectAll = (e) => {
    setIsCheckAll(!isCheckAll);
    setIsCheck(data.map((li) => li._id));
    if (isCheckAll) {
      setIsCheck([]);
    }
  };

  // HANDLE CHECKED CURRENT
  const handleClick = (e) => {
    const { id, checked } = e.target;
    setIsCheckAll(true);
    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== id));
    }
  };

  // UNTOGGLE CHECKED TOP
  useEffect(() => {
    if (isCheck.length < 1) {
      setIsCheckAll(false);
    }
  }, [isCheck, setIsCheckAll]);

  useEffect(() => {
    handleCheckedRows(isCheck);
  }, [isCheck, handleCheckedRows]);

  return (
    <>
      <table className="table">
        <thead className="thead">
          <tr>
            <th>{Object.values(groceryPermissions).some((key) => [PERMISSIONS.updateRequests].includes(key.name)) && <Checkbox type="checkbox" name="select-all-employees" id="selectAll" handleClick={handleSelectAll} isChecked={isCheckAll} />}</th>

            <th>Venue name</th>
            <th>Requested By</th>
            <th>Requested At</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody className="tbody">
          {data && data.length > 0 ? (
            data?.map((item, idx) => {
              const formatedDate = moment(item.requestedAt).format('DD/MM/YYYY HH:mm');
              return (
                <tr key={item._id}>
                  <td data-cy={`checkbox-item-employees-${idx}`}>{Object.values(groceryPermissions).some((key) => [PERMISSIONS.updateRequests].includes(key.name)) && <Checkbox key={item._id} type="checkbox" name={item._id} id={item._id} handleClick={handleClick} isChecked={isCheck.includes(item._id)} />}</td>

                  <td>{item?.changesName}</td>
                  <td>{item.requestedBy}</td>
                  <td>{formatedDate}</td>
                  <td className="y-top">
                    {Object.values(groceryPermissions).some((key) => [PERMISSIONS.updateRequests, PERMISSIONS.readRequests].includes(key.name)) && (
                      <div className="actions" data-cy="actions-popover-employees">
                        {location && location.pathname.includes(`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}`) && (
                          <>
                            {Object.values(groceryPermissions).some((key) => [PERMISSIONS.updateRequests].includes(key.name)) && (
                              <div className="actions-group" style={{ border: 'none' }}>
                                {getGroceryPermissions(groceryPermissions, PERMISSIONS.updateRequests) && (
                                  <button className="btn btn-actions" type="button" data-cy="actions-edit-employee" onClick={() => handleToggle({ name: 'update', state: updateActions, data: item._id })} style={{ margin: '0' }}>
                                    <span className="icon">
                                      <img alt="Delete" src="/assets/icons/edit-user.svg" />
                                    </span>
                                    <span className="text">
                                      <strong>EDIT</strong>
                                    </span>
                                  </button>
                                )}
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    )}
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={20}>
                <Message className="content-message">
                  <h2 style={{ color: '#666' }}>{contentMessage.dataNotFound}</h2>
                </Message>
              </td>
            </tr>
          )}
        </tbody>
      </table>

      {data && data.length > 0 && <Pagination {...pagination} />}
    </>
  );
};

export default Table;
