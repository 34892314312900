import React, { useState, useCallback, useEffect } from 'react';

import axios from 'axios';
import { AXIOS_API_CALL } from '../../../utils/endpoint';
import { SERVER_URL } from '../../../config/index';
import { PERMISSIONS } from '../../../utils/permissions';

import { useAuth } from '../../../context/useAuth';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

import { Main } from '../../../components/content';
import { Collapse } from 'antd';
import { notification } from 'antd';
import CheckboxCms from '../../../components/checkbox-cms';

import CmsExploreNetworkSection1 from './sections/CmsExploreNetworkSection1';
import CmsExploreNetworkSection2 from './sections/CmsExploreNetworkSection2';

import { constructInitialFormSection1State, constructInitialFormSection2State, constructInitialEnImagesState, constructInitialEnImagesPreviewState } from './helpers/helpers';

const CmsExploreNetwork = () => {
  const { user } = useAuth();
  const [groceryPermissions, setGroceryPermissions] = useState([]);
  const [initialData, setInitialData] = useState(null);
  const navigate = useNavigate();
  const [saving, setSaving] = useState(false);

  // GET USER TOKEN
  useEffect(() => {
    const { token } = user;

    if (user && token) {
      const decodeToken = jwtDecode(token);
      const permissions = decodeToken.roleData?.permissions;

      if (location.pathname.includes(`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}`)) {
        if (Object.keys(permissions).some((permission) => permission.includes(PERMISSIONS.grocery))) {
          setGroceryPermissions(permissions.grocery);
        }
      }
    }
  }, [user, location]);

  // GENERAL SETTINGS HANDLE
  const [formGeneralSettings, setFormGeneralSettings] = useState({
    hide: false,
  });

  // SECTION 1 HANDLE
  const [enImages, setEnImages] = React.useState({
    section1Icon1En: '',
    section1Icon2En: '',
    section2Image1En: '',

    section1Icon1Cg: '',
    section1Icon2Cg: '',
    section2Image1Cg: '',
  });
  const [enImagesErrors, setEnImagesErrors] = React.useState({
    section1Icon1En: false,
    section1Icon2En: false,
    section2Image1En: false,

    section1Icon1Cg: false,
    section1Icon2Cg: false,
    section2Image1Cg: false,
  });
  const [enImagesPreview, setEnImagesPreview] = React.useState({
    section1Icon1En: '',
    section1Icon2En: '',
    section2Image1En: '',

    section1Icon1Cg: '',
    section1Icon2Cg: '',
    section2Image1Cg: '',
  });

  const [formSection1, setFormSection1] = useState({
    hide: false,
    en: {
      title1: '',
      title2: '',
      subTitle1Part1: '',
      subTitle1Part2: '',
      subTitle2Part1: '',
      subTitle2Part2: '',
      button1Text: '',
      button2Text: '',
      button1Url: '',
      button2Url: '',
    },
    cg: {
      title1: '',
      title2: '',
      subTitle1Part1: '',
      subTitle1Part2: '',
      subTitle2Part1: '',
      subTitle2Part2: '',
      button1Text: '',
      button2Text: '',
      button1Url: '',
      button2Url: '',
    },
  });

  const [formSection1Errors, setFormSection1Errors] = React.useState({
    en: {
      title1: false,
      title2: false,
      subTitle1Part1: false,
      subTitle1Part2: false,
      subTitle2Part1: false,
      subTitle2Part2: false,
      button1Text: false,
      button2Text: false,
      button1Url: false,
      button2Url: false,
    },
    cg: {
      title1: false,
      title2: false,
      subTitle1Part1: false,
      subTitle1Part2: false,
      subTitle2Part1: false,
      subTitle2Part2: false,
      button1Text: false,
      button2Text: false,
      button1Url: false,
      button2Url: false,
    },
  });

  const handleInputChangeFormSection1 = useCallback((e) => {
    const { name, value } = e.target;
    const [lang, field] = name.split('_');

    if (name === 'section1_hide') {
      setFormSection1((prevState) => ({
        ...prevState,
        hide: !prevState.hide,
      }));
      return;
    }

    setFormSection1((prevState) => ({
      ...prevState,
      [lang]: {
        ...prevState[lang],
        [field]: value,
      },
    }));
  }, []);
  const onChangeTabSection1 = useCallback(() => {}, []);
  // SECTION 1 HANDLE

  // SECTION 2 HANDLE
  const [formSection2, setFormSection2] = React.useState({
    hide: false,
    en: {
      mainHeading: '',
      list1item1: '',
      list1item2: '',
      list1item3: '',
      list1item4: '',
    },
    cg: {
      mainHeading: '',
      list1item1: '',
      list1item2: '',
      list1item3: '',
      list1item4: '',
    },
  });
  const [formSection2Errors, setFormSection2Errors] = React.useState({
    en: {
      mainHeading: false,
      list1item1: false,
      list1item2: false,
      list1item3: false,
      list1item4: false,
    },
    cg: {
      mainHeading: false,
      list1item1: false,
      list1item2: false,
      list1item3: false,
      list1item4: false,
    },
  });
  const handleInputChangeFormSection2 = useCallback((e) => {
    const { name, value } = e.target;
    const [lang, field] = name.split('_');

    if (name === 'section2_hide') {
      setFormSection2((prevState) => ({
        ...prevState,
        hide: !prevState.hide,
      }));
      return;
    }

    setFormSection2((prevState) => ({
      ...prevState,
      [lang]: {
        ...prevState[lang],
        [field]: value,
      },
    }));
  }, []);
  const onChangeTabSection2 = useCallback(() => {}, []);
  // SECTION 2 HANDLE

  // HANDLE FORM SUBMIT
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const { token } = user;

    // Initialize error flag
    let hasError = false;

    // Create a copy of the error state
    let newFormSection1Errors = { ...formSection1Errors };
    let newFormSection2Errors = { ...formSection2Errors };

    // Validate Section 1 (EN)
    Object.keys(formSection1.en).forEach((key) => {
      if (!formSection1.en[key]) {
        newFormSection1Errors.en[key] = true;
        hasError = true;
      } else {
        newFormSection1Errors.en[key] = false;
      }
    });

    // Validate Section 1 (CG)
    Object.keys(formSection1.cg).forEach((key) => {
      if (!formSection1.cg[key]) {
        newFormSection1Errors.cg[key] = true;
        hasError = true;
      } else {
        newFormSection1Errors.cg[key] = false;
      }
    });

    // Validate Section 2 (EN)
    Object.keys(formSection2.en).forEach((key) => {
      if (!formSection2.en[key]) {
        newFormSection2Errors.en[key] = true;
        hasError = true;
      } else {
        newFormSection2Errors.en[key] = false;
      }
    });

    // Validate Section 2 (CG)
    Object.keys(formSection2.cg).forEach((key) => {
      if (!formSection2.cg[key]) {
        newFormSection2Errors.cg[key] = true;
        hasError = true;
      } else {
        newFormSection2Errors.cg[key] = false;
      }
    });

    // check if there are any fcImages that have value of '' (empty string)
    Object.keys(enImages).forEach((key) => {
      if (enImages[key] === null) {
        setEnImagesErrors((prevState) => ({
          ...prevState,
          [key]: true,
        }));
        hasError = true;
      } else {
        setEnImagesErrors((prevState) => ({
          ...prevState,
          [key]: false,
        }));
      }
    });

    setFormSection1Errors(newFormSection1Errors);
    setFormSection2Errors(newFormSection2Errors);

    // Prevent form submission if there are errors
    if (hasError) {
      notification.error({
        message: 'Please fill in all required fields and upload all images',
        placement: 'topRight',
      });
      return;
    }

    setSaving(true);

    const payload = {
      enHide: formGeneralSettings.hide || false,

      section1Hide: formSection1.hide || false,
      section2Hide: formSection2.hide || false,

      section1Card1TitleEn: formSection1.en.title1 || '',
      section1Card1SubtitlePart1En: formSection1.en.subTitle1Part1 || '',
      section1Card1SubtitlePart2En: formSection1.en.subTitle1Part2 || '',
      section1Card2SubtitlePart1En: formSection1.en.subTitle2Part1 || '',
      section1Card2SubtitlePart2En: formSection1.en.subTitle2Part2 || '',
      section1Card1ButtonTextEn: formSection1.en.button1Text || '',
      section1Card1ButtonUrlEn: formSection1.en.button1Url || '',
      section1Card2TitleEn: formSection1.en.title2 || '',
      section1Card2ButtonTextEn: formSection1.en.button2Text || '',
      section1Card2ButtonUrlEn: formSection1.en.button2Url || '',

      section1Card1TitleCg: formSection1.cg.title1 || '',
      section1Card1SubtitlePart1Cg: formSection1.cg.subTitle1Part1 || '',
      section1Card1SubtitlePart2Cg: formSection1.cg.subTitle1Part2 || '',
      section1Card2SubtitlePart1Cg: formSection1.cg.subTitle2Part1 || '',
      section1Card2SubtitlePart2Cg: formSection1.cg.subTitle2Part2 || '',
      section1Card1ButtonTextCg: formSection1.cg.button1Text || '',
      section1Card1ButtonUrlCg: formSection1.cg.button1Url || '',
      section1Card2TitleCg: formSection1.cg.title2 || '',
      section1Card2ButtonTextCg: formSection1.cg.button2Text || '',
      section1Card2ButtonUrlCg: formSection1.cg.button2Url || '',

      section2MainHeadingEn: formSection2.en.mainHeading || '',
      section2List1Item1En: formSection2.en.list1item1 || '',
      section2List1Item2En: formSection2.en.list1item2 || '',
      section2List1Item3En: formSection2.en.list1item3 || '',
      section2List1Item4En: formSection2.en.list1item4 || '',

      section2MainHeadingCg: formSection2.cg.mainHeading || '',
      section2List1Item1Cg: formSection2.cg.list1item1 || '',
      section2List1Item2Cg: formSection2.cg.list1item2 || '',
      section2List1Item3Cg: formSection2.cg.list1item3 || '',
      section2List1Item4Cg: formSection2.cg.list1item4 || '',

      // IMAGES
      section1Card1IconEn: enImages.section1Icon1En || null,
      section1Card2IconEn: enImages.section1Icon2En || null,
      section2Image1En: enImages.section2Image1En || null,

      section1Card1IconCg: enImages.section1Icon1Cg || null,
      section1Card2IconCg: enImages.section1Icon2Cg || null,
      section2Image1Cg: enImages.section2Image1Cg || null,
    };

    try {
      if (!initialData || initialData.length === 0) {
        const response = await axios.post(
          `${SERVER_URL}/${AXIOS_API_CALL.createEnDocument}`,
          {
            ...payload,
          },
          {
            withCredentials: false,
            headers: { Authorization: `Bearer ${token}` },
          }
        );
      } else {
        const response = await axios.put(
          `${SERVER_URL}/${AXIOS_API_CALL.updateEnDocument}/${initialData[0]?._id}`,
          {
            ...payload,
          },
          {
            withCredentials: false,
            headers: { Authorization: `Bearer ${token}` },
          }
        );
      }
    } catch (error) {
      console.error(error);
    }
    await new Promise((resolve) => setTimeout(resolve, 1000));
    notification.success({
      message: 'Data saved successfully',
    });
    setSaving(false);
    setTimeout(() => {
      navigate(0);
    }, 1000);
  };

  // HANDLE FETCH AND SET INITIAL DATA
  const handleFetchData = async () => {
    const { token } = user;
    try {
      const response = await axios.get(`${SERVER_URL}/${AXIOS_API_CALL.getEnDocument}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${token}` },
      });
      setInitialData(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    handleFetchData();
  }, []);

  useEffect(() => {
    if (!initialData) return;
    const data = initialData[0];
    setFormGeneralSettings((prevState) => ({
      ...prevState,
      hide: initialData[0]?.enHide || false,
    }));
    setFormSection1((prevState) => constructInitialFormSection1State(data, prevState));
    setFormSection2((prevState) => constructInitialFormSection2State(data, prevState));
    setEnImages((prevState) => constructInitialEnImagesState(data, prevState));
    setEnImagesPreview((prevState) => constructInitialEnImagesPreviewState(data, prevState));
  }, [initialData]);
  // HANDLE FETCH AND SET INITIAL DATA

  // PANEL AND TABS RELATED FUNCTIONS
  const { Panel } = Collapse;
  const onChange = useCallback(() => {}, []);
  // PANEL AND TABS RELATED FUNCTIONS

  const handleInputChangeGeneralSettings = (e) => {
    const { name, checked } = e.target;
    if (name === 'en_hide') {
      setFormGeneralSettings((prevState) => ({
        ...prevState,
        hide: !prevState.hide,
      }));
    }
  };

  return (
    <div className="cms cms-explore-network">
      <h2 className="page-title">CMS Explore network</h2>
      {/* Main Content */}
      <form className="cms-form cms-form-section cms-form-section1">
        <Main className="section__content relative">
          <Collapse onChange={onChange}>
            {/* SECTION 1 (Companies - Partners) */}
            <Panel header="SECTION 1 (Companies - Partners)" key="1">
              <CmsExploreNetworkSection1 onChangeTabSection1={onChangeTabSection1} handleInputChangeFormSection1={handleInputChangeFormSection1} formSection1={formSection1} setEnImages={setEnImages} enImages={enImages} enImagesErrors={enImagesErrors} enImagesPreview={enImagesPreview} formSection1Errors={formSection1Errors} />
            </Panel>
            {/* SECTION 1 (Companies - Partners) */}
            {/* SECTION 2 (Everyone wins with Fitpass!) */}
            <Panel header="SECTION 2 (Everyone wins with Fitpass!)" key="2">
              <CmsExploreNetworkSection2 onChangeTabSection2={onChangeTabSection2} formSection2={formSection2} handleInputChangeFormSection2={handleInputChangeFormSection2} setEnImages={setEnImages} enImages={enImages} enImagesErrors={enImagesErrors} enImagesPreview={enImagesPreview} formSection2Errors={formSection2Errors} />
            </Panel>
            {/* SECTION 2 (Everyone wins with Fitpass!) */}
          </Collapse>
          <CheckboxCms inputName="en_hide" isChecked={formGeneralSettings.hide} handleChange={handleInputChangeGeneralSettings} label="Hide entire page on website" tooltipText="Checking this will hide entire page on website" />
          <button className="btn btn-primary pl-3 pr-3 mt-3 mb-5" onClick={(e) => handleFormSubmit(e)}>
            {saving ? 'Saving changes...' : 'Save changes'}
          </button>
        </Main>
      </form>
      {/* Main Content */}
    </div>
  );
};
export default CmsExploreNetwork;
