import EditHistory from '../pages/grocery/edit-history-page/EditHistory';

const AXIOS_API_CALL = {
  // ROLES
  getRole: 'get-role',
  getRoles: 'get-roles',
  createRole: 'create-role',
  updateRole: 'update-role',
  deleteRole: 'delete-role',
  bulkDeleteRole: 'bulk-delete-role',

  // CUSTOMERS
  getCustomers: 'dashboard/customers',
  deleteCustomers: 'dashboard/customers/delete-account',
  updateCustomers: 'dashboard/customers/edit-customer',
  bulkUpdateStatus: 'dashboard/bulk-deactivate-customer',
  createCustomer: 'dashboard/customers/create-customer',

  // EMPLOYEES
  usersEmployees: 'users/employees',
  usersGetEmoloyee: 'users/get-employee',
  createEmployee: 'create-employee',
  usersUpdateEmoloyee: 'users/update-employee',
  usersBulkDeactivateEmployee: 'users/bulk-deactivate-employee',
  usersDeleteEmployee: 'users/delete-employee',

  // VENUES
  venues: 'venues',
  getVenueById: 'get-venue-by-id',

  // REQUESTS
  requests: 'venue-requests',
  editRequest: 'review-edit-request',
  editRequestHistory: 'get-all-edit-venue-history',

  // NYAMI REQUESTS
  niamyRequests: 'nyami-requests',

  // GALLERY
  galleryImages: 'images',
  galleryImagesSearch: 'images?searchTerm=',
  galleryUploadLocalImage: 'upload-local-image',
  updateGalleryImage: 'images-update',
  deleteGalleryImage: 'images-delete',
  uploadLocalImage: 'upload-local-image',
  getImagesById: 'images',

  productGalleryImages: 'product-gallery-images',

  // ADDRESSES - CUSTOMERS
  createDashboardAddresses: 'dashboard/addresses/create-address',
  deleteDashboardAddresses: 'dashboard/addresses/delete-address',

  // PRODUCTS
  getProduct: 'get-product-data',
  getProducts: 'products',
  deleteProduct: 'delete-product',
  deleteProductBulk: 'bulk-delete-product',
  updateProduct: 'update-product',

  // IMPORT FROM NETSUITE
  importProductFromNetSuite: 'import-product-from-netsuite',
  importCategoriesFromNetSuite: 'get-netsuite-categories',

  // ORDERS
  orders: 'orders',
  updateOrder: 'update-order',
  orderDetails: 'order-details',

  // EMPLOYEE E-MAIL VERIFICATION
  employeeEmailVerification: 'employee-email-verification',

  // ACTIVITY LOG
  logs: 'logs',
  logsDownload: 'logs/download',
  logsCreateFile: 'logs/create-file',

  // CATEGORIES
  categories: 'categories',
  categoriesGetAll: 'categories-get-all',
  checkCategorySlug: 'check-category-slug',
  checkCategoryName: 'check-category-name',

  // ATTRIBUTES
  attributes: 'attributes',
  createAttribute: 'create-attribute',
  atributesOfDisciplines: 'get-all-paginated-discipline',
  venueAttributesByDisciplines: 'get-all-discipline-attributes',

  // GLOBAL ATTRIBUTES
  globalAttributes: 'get-all-global-attribute',
  createGlobalAttribute: 'create-global-attribute',
  deleteGlobalAttributes: 'delete-global-attributes',

  // DISCIPLINES
  disciplines: 'get-all-discipline',
  getDiscipline: 'get-discipline-by-id',
  updateDiscipline: 'update-discipline',

  // UNITS
  units: 'units',
  addUnitToAttribute: 'add-unit-to-attribute',

  // LOGIN
  login: 'login',

  // FORGOT PASSWORD
  forgotPassword: 'forgot-password',

  // PASSWORD RESET
  passwordReset: 'password-reset',

  // PROFILE
  updateEmployee: 'users/update-employee',

  // USERS
  usersGetEmployee: 'users/get-employee',
  employeeLogs: 'employee/logs',

  // PERMISSIONS
  getPermissions: 'get-permissions',

  // USER CHECK TOKEN
  userCheckToken: 'users/check-token',

  // GROCERY
  grocery: 'grocery',
  departmentGrocery: 'grocery',

  // VARIATIONS
  deleteVariation: 'delete-variation',

  // SLIDESHOW
  slideshowgalleryImages: 'slideshow-images',
  slideshowgalleryImagesSearch: 'slideshow-images?searchTerm=',
  slideshowgalleryUploadLocalImage: 'slideshow-upload-local-image',
  slideshowupdateGalleryImage: 'slideshow-images-update',
  slideshowdeleteGalleryImage: 'slideshow-images-delete',
  slideshowuploadLocalImage: 'upload-local-image',
  slideshowgetImagesById: 'slideshow-images',
  replaceOrderOfSliders: 'replace-order-of-sliders',

  updateFavoritesVenues: 'update-single-venue-fav',

  // CMS HOMEPAGE
  createHpDocument: 'cmshomepage',
  updateHpDocument: 'updatecmshomepage',
  getHpDocument: 'getcmshomepage',

  // CMS FOR COMPANIES
  createFcDocument: 'cmsforcompanies',
  updateFcDocument: 'updatecmsforcompanies',
  getFcDocument: 'getcmsforcompanies',

  // CMS FOR PARTNERS
  createFpDocument: 'cmsforpartners',
  updateFpDocument: 'updatecmsforpartners',
  getFpDocument: 'getcmsforpartners',

  // CMS FOR ABOUT US
  createAuDocument: 'cmsaboutus',
  updateAuDocument: 'updatecmsaboutus',
  getAuDocument: 'getcmsaboutus',

  // CMS FOR EXPLORE NETWORK
  createEnDocument: 'cmsexplorenetwork',
  updateEnDocument: 'updatecmsexplorenetwork',
  getEnDocument: 'getcmsexplorenetwork',

  // COUNTRY CODES
  getAllCountryCodes: 'country-code/get-all-country-code',
  createCountryCode: 'country-code/create-country-code',
  getCountryCodeById: 'country-code/get-by-id',
  updateCountryCodeById: 'country-code/update-country-code',
};

export { AXIOS_API_CALL };
