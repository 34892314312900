import React from 'react';
import { stateUS } from '../../../../../helpers/state';
import Loading from '../../../../../components/loading/Loading';
import SidebarModal from '../../../../../components/sidebarModal/SidebarModal';
import { Select, Input, Button, Form, Row, Col, Checkbox } from 'antd';

export const UpdateFormAllSettings = ({ updateProps, loadingCountryCode, updateModalFormRef, updateFormFields, onChange, toggleNewPassword, roles, editBtnLoader, handleOnFinish, setToggleUpdate }) => {
  return (
    <SidebarModal {...updateProps} className="sidebar__modal--right" title="Edit Country code">
      {!loadingCountryCode ? (
        <Form name="update" ref={updateModalFormRef} wrapperCol={{ span: 24 }} initialValues={{ remember: false }} onFinish={(data) => handleOnFinish({ name: 'update', formData: data })} fields={updateFormFields}>
          <Form.Item
            name="countryCode"
            label="Country code:"
            rules={[
              {
                required: true,
                message: 'Please input country code!',
              },
            ]}
          >
            <Input placeholder="Country code:" />
          </Form.Item>

          <Form.Item
            name="countryName"
            label="Country name:"
            rules={[
              {
                required: true,
                message: 'Please input country name!',
              },
            ]}
          >
            <Input placeholder="Country name:" />
          </Form.Item>

          <Form.Item
            name="countryTimezone"
            label="Country timezone:"
            rules={[
              {
                required: true,
                message: 'Please input country timezone!',
              },
            ]}
          >
            <Input placeholder="Country timezone:" />
          </Form.Item>

          <Form.Item name="updateCountryCodeId" style={{ display: 'none' }}>
            <Input type="hidden" />
          </Form.Item>

          <Form.Item className="ant-col-btn row-direction">
            <Row gutter={16} className="row-direction">
              <Col span={12}>
                <Button type="primary" htmlType="button" className="btn btn-primary-link btn-block" onClick={() => setToggleUpdate(false)}>
                  <span>Cancel</span>
                </Button>
              </Col>
              <Col span={12}>
                {!editBtnLoader ? (
                  <Button type="submit" htmlType="submit" className="btn btn-primary btn-block">
                    <span>Edit Country code</span>
                  </Button>
                ) : (
                  <Button type="button" className="btn btn-primary-outline btn-block">
                    <span>Editing...</span>
                  </Button>
                )}
              </Col>
            </Row>
          </Form.Item>
        </Form>
      ) : (
        <Loading />
      )}
    </SidebarModal>
  );
};
