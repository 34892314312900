import React, { memo } from 'react';
import { Tabs } from 'antd';
import Section1En from '../tabs/en/Section1En';
import Section1Cg from '../tabs/cg/Section1Cg';
import CheckboxCms from '../../../../components/checkbox-cms';

const CmsExploreNetworkSection1 = ({ onChangeTabSection1, handleInputChangeFormSection1, formSection1, enImages, enImagesPreview, setEnImages, formSection1Errors, enImagesErrors = { enImagesErrors } }) => {
  const { TabPane } = Tabs;

  const sectionProps = {
    handleInputChangeFormSection1,
    formSection1,
    enImages,
    enImagesPreview,
    setEnImages,
    formSection1Errors,
    enImagesErrors,
  };

  return (
    <>
      <Tabs defaultActiveKey="1" onChange={onChangeTabSection1}>
        <TabPane key="1" tab="EN">
          <Section1En {...sectionProps} />
        </TabPane>
        <TabPane key="2" tab="CG">
          <Section1Cg {...sectionProps} />
        </TabPane>
      </Tabs>
      <CheckboxCms inputName="section1_hide" isChecked={formSection1.hide} handleChange={handleInputChangeFormSection1} label="Hide entire section on website" tooltipText="Checking this will hide entire section on website" />
    </>
  );
};
export default memo(CmsExploreNetworkSection1);
